<template>
  <div>
    <v-card>
      <v-card-title> Lançamento avulsos </v-card-title>
    </v-card>
    <!-- card da container dos outros cards -->
    <v-card class="mt-2">
      <!-- <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title> -->
      <v-data-table :headers="headers" :items="getDetached" :search="search">
        <!-- item da foto -->
        <template slot="item.picture" slot-scope="{ item }">
          <v-img :src="item.picture" max-width="200"></v-img>
        </template>
        <!-- item das datas -->
        <template slot="item.deviceDate" slot-scope="{ item }">
          <v-card class="pa-2">
            <b>Dispositivo:</b>
            {{ new Date(item.deviceDate).toLocaleString() }}
          </v-card>
          <v-card class="pa-2">
            <b>Informada:</b>
            {{ new Date(item.dateTime).toLocaleString() }}
          </v-card>
          <v-card class="pa-2">
            <b>Sincronizado:</b>
            {{ new Date(item.createdAt).toLocaleString() }}
          </v-card>
        </template>
        <!-- item do usuário -->
        <template slot="item.userId" slot-scope="{ item }">
          {{ getUserNameById(item.userId) }}
        </template>
        <!-- item do dispositivo -->
        <template slot="item.uniqueId" slot-scope="{ item }">
          {{ getDeviceByUniqueId(item.uniqueId) }}
        </template>
        <!-- item do dispositivo -->
        <template slot="item.actions" slot-scope="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="edit(item)" v-on="on" v-bind="attrs">
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </template>
            <span>Importar </span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="del(item)" v-on="on" v-bind="attrs">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Descartar </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <!-- dialog de vinculação -->
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            Vincular uma movimentação avulsa para uma ordem de usuário
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-row class="mt-2">
          <!-- coluna da imagem -->
          <v-col v-show="detached.picture">
            <v-card>
              <v-card-title> Imagem enviada pelo usuário </v-card-title>
              <v-card-text>
                <!-- div com os botões -->
                <div class="btnAngle">
                  <v-btn
                    text
                    icon
                    large
                    color="primary"
                    @click="imageAngle -= 90"
                  >
                    <v-icon>mdi-rotate-left</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    icon
                    large
                    color="primary"
                    @click="imageAngle += 90"
                  >
                    <v-icon>mdi-rotate-right</v-icon>
                  </v-btn>
                </div>
                <v-img
                  :src="detached.picture"
                  contain
                  :style="'transform: rotate(' + imageAngle + 'deg);'"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- coluna dos dados -->
          <v-col>
            <v-card class="mr-2">
              <v-card-title> Dados informados pelo o usuário </v-card-title>
              <v-card-text>
                <v-text-field
                  label="Data informada"
                  outlined
                  type="datetime-local"
                  clearable
                  v-model="detached.dateTime"
                ></v-text-field>
                <v-autocomplete
                  :items="['Carga', 'Descarga']"
                  outlined
                  label="Operação"
                  clearable
                  v-model="detached.operation"
                ></v-autocomplete>
                <v-autocomplete
                  :items="getUserSelect"
                  outlined
                  label="Usuário que ficará vinculado a movimentação"
                  clearable
                  v-model="detached.userId"
                ></v-autocomplete>
                <v-text-field
                  label="Material"
                  outlined
                  clearable
                  v-model="detached.itemName"
                  :counter="255"
                ></v-text-field>
                <v-text-field
                  label="Placa"
                  outlined
                  clearable
                  v-model="detached.plate"
                  :counter="255"
                ></v-text-field>
                <v-text-field
                  label="Quantidade"
                  outlined
                  clearable
                  type="number"
                  v-model="detached.amount"
                ></v-text-field>
                <v-text-field
                  label="Forncecedor"
                  outlined
                  clearable
                  v-model="detached.suplierName"
                  :counter="255"
                ></v-text-field>
                <v-text-field
                  label="Obra"
                  outlined
                  clearable
                  v-model="detached.constructionName"
                  :counter="255"
                ></v-text-field>
                <v-textarea
                  v-model="detached.description"
                  outlined
                  clearable
                  rows="4"
                  label="Descrição"
                  :counter="255"
                ></v-textarea>
                <v-divider></v-divider>
                <v-text-field
                  v-show="selected.id"
                  class="mt-2"
                  label="Movimentação vinculada"
                  outlined
                  v-model="selected.id"
                  readonly
                  disabled
                ></v-text-field>
              </v-card-text>
              <v-card-actions v-show="selected.id">
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="selected = {}">Cancelar</v-btn>
                <v-btn color="success" @click="send">Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- linha com as ordens -->
        <v-row>
          <v-col>
            <v-card class="mt-3" outlined>
              <v-card-title> Ordens aguardando finalização </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left">ID</th>
                      <th class="text-left">Ordem de Compra</th>
                      <th class="text-center">Item</th>
                      <th class="text-center">Carregado?</th>
                      <th class="text-left">Usuário Coleta</th>
                      <th class="text-left">Quantidade</th>
                      <th class="text-left">Descrição</th>
                      <th class="text-left">Enviado Por</th>
                      <th class="text-left">Cadastro</th>
                      <th class="text-left">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in getBOtoUsers" :key="index">
                      <td>{{ item.id }}</td>
                      <td>
                        <v-card>
                          <v-card-text>
                            <v-row justify="space-around">
                              <b>Nome:</b>
                              <p>
                                {{
                                  getOrderById(item.buyOrderId)
                                    ? getOrderById(item.buyOrderId).name
                                    : ""
                                }}
                              </p>
                            </v-row>
                            <v-row justify="space-around">
                              <b>Numero:</b>
                              <p>
                                {{
                                  getOrderById(item.buyOrderId)
                                    ? getOrderById(item.buyOrderId).buyOrder
                                    : ""
                                }}
                              </p>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </td>
                      <td>
                        <OrdemItems
                          :item="getItemOrdemById(item.buyOrderId, item.itemId)"
                        />
                      </td>
                      <td>{{ item.loaded ? "SIM" : "NÃO" }}</td>
                      <td>{{ getUserNameById(item.userId) }}</td>
                      <td>{{ item.amount }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ getUserNameById(item.createUserId) }}</td>
                      <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                      <td>
                        <v-btn color="primary" @click="selected = { ...item }"
                          >Usar esta</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrdemItems from "../components/Ordem/Item";
export default {
  name: "Avulso",
  components: { OrdemItems },
  data: () => ({
    search: "",
    selected: {},
    dialog: false,
    detached: {},
    imageAngle: 0,
    headers: [
      {
        text: "id",
        align: "start",
        value: "id",
      },
      { text: "Usuário", value: "userId" },
      { text: "Dispositivo", value: "uniqueId" },
      { text: "Operação", value: "operation" },
      { text: "Material", value: "itemName" },
      { text: "Foto", value: "picture" },
      { text: "Quantidade", value: "amount" },
      { text: "Fornecedor", value: "suplierName" },
      { text: "Obra", value: "constructionName" },
      { text: "Descrição", value: "description" },
      { text: "Data", value: "deviceDate" },
      { text: "Ações", value: "actions" },
    ],
    headers2: [
      {
        text: "id",
        align: "start",
        value: "id",
      },
      { text: "Item", value: "itemId" },
      { text: "Dispositivo", value: "uniqueId" },
      { text: "Operação", value: "operation" },
      { text: "Material", value: "itemName" },
      { text: "Foto", value: "picture" },
      { text: "Quantidade", value: "amount" },
      { text: "Fornecedor", value: "providerId" },
      { text: "Obra", value: "constructionId" },
      { text: "Descrição", value: "description" },
      { text: "Data", value: "deviceDate" },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    edit(item) {
      this.dialog = true;
      this.detached = { ...item };
      this.detached.dateTime = new Date(item.dateTime)
        .toISOString()
        .slice(0, 16)
        .replace("T", " ");
    },
    del(item) {
      if (confirm(`Deseja realmente excluir o registro com id ${item.id}?`)) {
        this.$store.dispatch("deletDetached", item);
      }
    },
    send() {
      this.detached.bo2userId = this.selected.id;
      this.detached.callback = function () {
        this.detached = {};
        this.dialog = false;
      };
      this.$store.dispatch("saveDetached", this.detached);
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters([
      "getUserNameById",
      "getDeviceByUniqueId",
      "getDetached",
      "getBOtoUsers",
      "getConstructionNameById",
      "getProviderNameById",
      "getItemOrdemById",
      "getOrderById",
      "getUserSelect",
    ]),
  },
  watch: {},
  mounted() {
    this.$store.dispatch("loadDevices");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadDetached");
    this.$store.dispatch("loadBOtoUser");
    this.$store.dispatch("loadConstructions");
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadProviders");
    this.$store.dispatch("loadUsers");
  },
};
</script>

<style>
.btnAngle {
  position: relative;
  left: 45%;
  z-index: 1;
  height: 0;
}
</style>